import React, {  } from 'react';
import './loader.scss';

interface iLoader {
  half?: boolean
}

function Loader(props: iLoader) {
  return (
    <div className={props.half ? 'loader-space half-space' : 'loader-space'}>
      <div className="lds-ripple"><div></div><div></div></div>
    </div>
  );
}

export default Loader;
